import { Controller } from 'stimulus'
import { get, put } from '@rails/request.js'
import { enter, leave } from 'el-transition'
import { dispatchEvent } from 'src/dispatch-event'

export default class extends Controller {
  static targets = ['spinner', 'page', 'dismissButton']
  static values = {
    url: String,
    syncing: Boolean,
  }

  onClick(event) {
    if (event) {
      event.preventDefault()
    }
    this.checkOrderState()
  }

  update(event) {
    if (event) {
      event.preventDefault()
    }
    this.updateOrderState()
  }

  updateOrderState() {
    const response = put(this.urlValue)
    this.checkOrderState()
  }

  async checkOrderState(event) {
    dispatchEvent(document, 'app:before-request')

    if (this.syncingValue) {
      await leave(this.pageTarget)
      enter(this.spinnerTarget)
    }

    const response = await get(this.urlValue)
    const body = await response.html
    if (this.syncingValue) {
      await leave(this.spinnerTarget)
    }

    this.pageTarget.innerHTML = body

    if (this.syncingValue) {
      enter(this.pageTarget)
    }

    dispatchEvent(document, 'app:after-request')
  }
}
