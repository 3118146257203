import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'input',
    'form',
    'loader',
    'results',
    'noResults',
    'page',
    'hideOnSearch',
    'inputEmptyIcon',
    'inputFullIcon',
    'resultsSummary',
  ]
  connect() {
    this.element[this.identifier] = this
  }

  submit(event) {
    if (event) {
      event.preventDefault()
    }

    if (this.inputTarget.value && this.inputTarget.value !== '') {
      console.log(this.formTarget)
      this.formTarget.requestSubmit()
    }
  }

  updateUIuponSubmit() {
    this.applyRelevantInputIcon()

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden')
    }

    this.resultsTargets.forEach((e) => e.classList.add('hidden'))
    this.noResultsTargets.forEach((e) => e.classList.add('hidden'))

    if (this.hasHideOnSearchTarget) {
      this.hideOnSearchTarget.hidden = true
    }
  }

  applyRelevantInputIcon() {
    if (!this.hasInputEmptyIconTarget && !this.hasInputFullIconTarget) {
      return
    }

    if (this.inputTarget.value == '') {
      this.inputFullIconTarget.classList.add('hidden')
      this.inputEmptyIconTarget.classList.remove('hidden')
    } else {
      this.inputFullIconTarget.classList.remove('hidden')
      this.inputEmptyIconTarget.classList.add('hidden')
    }
  }

  clearInput(event) {
    event.preventDefault()
    this.inputTarget.value = ''
    this.inputTarget.dispatchEvent(new Event('change'))
    this.formTarget.dispatchEvent(new CustomEvent('searchCleared'))
    this.inputTarget.focus()
    this.resultsTargets.forEach((e) => e.classList.add('hidden'))
    this.noResultsTargets.forEach((e) => e.classList.remove('hidden'))
    this.applyRelevantInputIcon()
  }
}
