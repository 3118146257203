// Custom TailwindCSS modals for confirm dialogs

const Rails = require('@rails/ujs')

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm

// Elements we want to listen to for data-confirm
const elements = [
  'a[data-confirm]',
  'button[data-confirm]',
  'input[type=submit][data-confirm]',
]

const createConfirmModal = (element) => {
  var id = 'confirm-modal-' + String(Math.random()).slice(2, -1)
  var confirm = element.dataset.confirm

  var content = `
    <div id="${id}" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table text-base text-left" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">

        <div class="bg-white mx-auto rounded-xl shadow px-8 py-6 max-w-sm">
          <h4>${confirm}</h4>

          <div class="flex justify-end items-center flex-wrap mt-8">
            <button data-behavior="commit" class="py-3 px-8 rounded-lg relative focus:ring-2 ring-offset-2 ring-dark outline-none font-nunito-sans text-sm leading-extra-snug whitespace-nowrap disabled:opacity-50 border-0 bg-dark text-white font-semibold mr-4">
              Confirm
            </button>

            <button data-behavior="cancel" class="py-3 px-8 rounded-lg relative focus:ring-2 ring-offset-2 ring-dark outline-none font-nunito-sans text-sm leading-extra-snug text-dark whitespace-nowrap disabled:opacity-50 border border-outline font-medium bg-none">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  `

  element.insertAdjacentHTML('afterend', content)

  var modal = element.nextElementSibling
  element.dataset.confirmModal = `#${id}`

  modal.addEventListener('keyup', (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      element.removeAttribute('data-confirm-modal')
      modal.remove()
    }
  })

  modal
    .querySelector("[data-behavior='cancel']")
    .addEventListener('click', (event) => {
      event.preventDefault()
      element.removeAttribute('data-confirm-modal')
      modal.remove()
    })
  modal
    .querySelector("[data-behavior='commit']")
    .addEventListener('click', (event) => {
      event.preventDefault()

      // Allow the confirm to go through
      Rails.confirm = () => {
        return true
      }

      // Click the link again
      element.click()

      // Remove the confirm attribute and modal
      element.removeAttribute('data-confirm-modal')
      Rails.confirm = old_confirm

      modal.remove()
    })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
