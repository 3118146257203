import { Controller } from 'stimulus'
import countdown from 'countdown'

const COUNTDOWN_ATTR_NAME = 'data-countdown'

export default class extends Controller {
  static targets = ['countdown']

  connect = () => {
    this.countdownTargets.forEach((element) => this.updateCountdown(element))
  }

  updateCountdown = (el) => {
    const countdownDate =
      el.getAttribute && el.getAttribute(COUNTDOWN_ATTR_NAME)
    if (countdownDate != null) {
      // Show the seconds only when the remaining time is less than 5 min
      countdown(
        new Date(countdownDate),
        (timeSpan) => {
          if (Math.abs(timeSpan.value) >= 300000) {
            el.innerHTML = timeSpan.toString()
            window.timeSpan = timeSpan
          }
        },
        ~(countdown.SECONDS | countdown.MILLISECONDS)
      )
      countdown(new Date(countdownDate), (timeSpan) => {
        if (Math.abs(timeSpan.value) < 300000) {
          el.innerHTML = timeSpan.toString()
          window.timeSpan = timeSpan
        }
      })
    }
  }
}
