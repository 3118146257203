// Use to dispatch an event without writing JS
// Example
// %div(data-controller="dispatch-event" data-dispatch-event-name="test-event" data-dispatch-event-detail-user-id="#{user.id}" data-action="dispatch-event#dispatch")

import { Controller } from 'stimulus'
import { dispatchEvent } from 'src/dispatch-event'

export default class extends Controller {
  static targets = ['element']
  static values = {
    onConnect: Boolean,
  }

  connect() {
    if (this.onConnectValue) {
      this.dispatch()
    }
  }

  dispatch() {
    const detail = {}

    Object.keys(Object.assign({}, this.element.dataset))
      .filter((attr) => attr.startsWith('eventDetail'))
      .forEach((attr) => {
        const key = attr.slice(11).replace(/^\w/, (c) => c.toLowerCase())
        detail[key] = this.element.dataset[attr]
      })

    this.data
      .get('name')
      .split(',')
      .forEach((name) => {
        if (this.hasElementTarget) {
          dispatchEvent(this.elementTarget, name, detail)
        } else {
          dispatchEvent(document, name, detail)
        }
      })
  }
}
