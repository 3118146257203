import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tabContent', 'tabSelector']

  static values = {
    activeTabCss: Array,
    currentTabId: String,
  }

  connect = () => {
    const id = this.currentTabIdValue
    const target = this.tabSelectorTargets.find(
      (t) => t.dataset.changeTabTargetId == id
    )

    if ((id !== null || id !== undefined || id !== '') && target) {
      target.click()
    }
  }

  changeTab = (event) => {
    let currentTabSelector = event.currentTarget
    this.tabContentTargets.forEach((item) => {
      if (item.id == currentTabSelector.dataset.changeTabTargetId) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })

    this.tabSelectorTargets.forEach((item) => {
      if (item == currentTabSelector) {
        item.classList.add(...this.activeTabCssValue)
      } else {
        item.classList.remove(...this.activeTabCssValue)
      }
    })

    event.preventDefault()
  }
}
