import { Controller } from 'stimulus'
import { animateHide } from 'src/helpers/animation-helper'

export default class extends Controller {
  static targets = ['toClose']
  static values = {
    timeout: Number,
    animationDirection: String,
  }

  connect() {
    if (this.timeoutValue > 0) {
      this.timeout = setTimeout(() => this.dismiss(), this.timeoutValue)
    }
  }

  dismiss() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    const animationDefaults = 'animate-out fade-out'

    if (this.hasAnimationDirectionValue) {
      animateHide(
        this.toCloseTarget,
        animationDefaults + ' ' + this.animationDirectionValue
      )
    } else {
      animateHide(
        this.toCloseTarget,
        animationDefaults + ' slide-out-to-bottom md:slide-out-to-top'
      )
    }
  }
}
