import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'
import { track } from 'src/analytics_event_tracker'

export default class extends Controller {
  static values = {
    eventName: String,
    eventProperties: Object,
  }

  options = {
    threshold: 1,
  }

  hasBeenDisplayed = false

  connect = () => {
    useIntersection(this, this.options)
  }

  appear(entry) {
    if (!this.hasBeenDisplayed) {
      track(this.eventNameValue, this.eventPropertiesValue)
      this.hasBeenDisplayed = true
    }
  }
}
