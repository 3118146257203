import { Controller } from 'stimulus'
import { track } from 'src/analytics_event_tracker'

export default class extends Controller {
  static targets = ['content']
  static values = {
    trackingPayload: Object,
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden')
    console.log('click')

    if (!this.contentTarget.classList.contains('hidden')) {
      track('opened_toggletip', this.trackingPayloadValue)
    }
  }
}
