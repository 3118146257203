import { Controller } from 'stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['modal']
  static values = {
    allowUrlChange: { type: Boolean, default: true },
  }

  async show(event) {
    event.preventDefault()

    this.modalTarget['lib--modal'].show()

    const link = event.currentTarget

    const response = await get(link.href, {
      headers: {
        'X-Show-In-Modal': true,
      },
    })

    if (response.ok) {
      const body = await response.html
      if (this.allowUrlChangeValue) this.changeUrl(link.href)
      this.setModalBody(body)
    }
  }

  setModalBody(body) {
    this.modalTarget['lib--modal'].setDynamicBody(body)
  }

  changeUrl(url) {
    this.modalTarget['lib--modal'].changeUrl(url)
  }
}
