// This module will read and process the events stored in a `frontend_analytics`
// cookie set by our backend, calling the appropriate tracking functions.
//
// It will then clear the cookie, ensuring we don't duplicate sending events
// across multiple page views.
export default function () {
  if (!window.analytics) {
    return
  }

  window.analytics.ready(() => {
    const cookieName = 'frontend_analytics'
    const cookieValue = ('; ' + document.cookie)
      .split(`; ${cookieName}=`)
      .pop()
      .split(';')[0]
    if (cookieValue === '') {
      return
    }

    const events = JSON.parse(atob(unescape(cookieValue)))
    events.forEach((eventData) => {
      if (eventData.type === 'track') {
        window.analytics.track(eventData.event, eventData.properties)
      } else {
        console.log('unknown event type:', eventData.type)
      }
    })

    const domain = window.env === 'production' ? '.lollipopai.com' : 'localhost'

    // clear out the frontend_analytics cookie.
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
  })
}
