import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['itemsList', 'addItemInput', 'placeholderLoader']

  static values = {
    swapProductPath: String,
  }

  select = (event) => {
    const line = event.detail[0]

    const that = this
    Rails.ajax({
      type: 'patch',
      url: this.swapProductPathValue,
      dataType: 'html',
      data: new URLSearchParams({
        product_id: line.productId,
        quantity: line.quantity,
        replacing_product_id: line.replacingProductId,
      }).toString(),
      success: (data) => {
        event.target.dispatchEvent(
          new CustomEvent('received_product', { detail: [data] })
        )
      },
    })
  }

  itemAdded = (event) => {
    let [data, status, xhr] = event.detail
    if (data.body) {
      this.itemsListTarget.outerHTML = data.body.innerHTML
    } else {
      console.warn('Non-html response, ignoring')
    }
    this.addItemInputTarget.value = ''
    this.placeholderLoaderTarget.classList.add('hidden')
  }

  itemAddStarted = () => {
    this.placeholderLoaderTarget.querySelector('.js--title').textContent =
      this.addItemInputTarget.value
    this.placeholderLoaderTarget.classList.remove('hidden')
  }
}
