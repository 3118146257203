import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

function loadSentry() {
  Sentry.init({
    dsn: 'https://0544ef9824884ab2966919bf513d565c@o512009.ingest.sentry.io/5610353',
    whitelistUrls: [/alpha\.lollipopai\.com/, /dxgikw68zl73i\.cloudfront\.net/],

    includePaths: [
      /https:\/\/alpha\.lollipopai\.com/,
      /https:\/\/dxgikw68zl73i\.cloudfront\.net/,
    ],

    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

if (process.env.NODE_ENV == 'production') {
  loadSentry()
  window.Sentry = Sentry
}
