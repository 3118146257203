import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filter']

  filter() {
    const newSearchParams = new URLSearchParams(window.location.search)
    for (const [key, value] of this.getParams()) {
      newSearchParams.set(key, value)
    }

    const url = `${window.location.pathname}?${newSearchParams.toString()}`
    Turbo.clearCache()
    Turbo.visit(url)
  }

  getParams() {
    let searchParams = this.filterTargets.map((t) => [t.name, t.value])
    if (searchParams.length === 0 && this.element instanceof HTMLFormElement) {
      searchParams = new FormData(this.element)
    }
    return new URLSearchParams(searchParams)
  }
}
