import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'modal',
    'imagePreview',
    'emptyImagePreview',
    'preselectedImageInput',
    'userUploadedImageInput',
  ]

  chooseCoverToUpload = (event) => {
    this.userUploadedImageInputTarget.click()
    event.preventDefault()
  }

  suggestedCoverSelected = (event) => {
    const selectedImageId = event.currentTarget.dataset.coverImage
    this.preselectedImageInputTarget.value = selectedImageId
    this.userUploadedImageInputTarget.value = ''

    this.imagePreviewTarget.src = event.currentTarget.dataset.coverImagePath
    this.imagePreviewTarget.classList.remove('hidden')
    if (this.hasEmptyImagePreviewTarget) {
      this.emptyImagePreviewTarget.classList.add('hidden')
    }

    this.hideModal()
  }

  onCoverFileChange = () => {
    this.loadFileFieldPreview()
    this.hideModal()
  }

  loadFileFieldPreview = () => {
    let file = this.userUploadedImageInputTarget.files[0]
    let fr = new FileReader()
    fr.readAsDataURL(file)
    fr.onload = (e) => {
      // rendering arbitrary SVG could be an XSS vuln and we don't accept it in
      // the backend
      if (e.currentTarget.result.slice(0, 18) !== 'data:image/svg+xml') {
        this.imagePreviewTarget.src = e.currentTarget.result
        this.imagePreviewTarget.classList.remove('hidden')
        this.preselectedImageInputTarget.value = ''
      }
      if (this.hasEmptyImagePreviewTarget) {
        this.emptyImagePreviewTarget.classList.add('hidden')
      }
    }
  }

  hideModal = () => {
    this.modalTarget['lib--modal'].hide()
  }
}
