import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'product', 'search']
  static values = {
    replacingProductId: String,
    replacingLineItemId: String,
    groupedAcrossSources: Boolean,
  }

  connect = () => {
    this.element[this.identifier] = this
  }

  search() {
    this.searchTarget['lib--search'].submit()
  }

  hideParentModal = () => {
    this.modalTarget['lib--modal'].hide() // NOTE: modal hiding will also call #selectProducts
  }

  bindValues = ({ replacingProductId, replacingLineItemId, searchTerm }) => {
    this.replacingProductIdValue = replacingProductId
    this.replacingLineItemIdValue = replacingLineItemId
    this.searchTarget['lib--search'].inputTarget.value = searchTerm
    this.search()
  }

  selectProducts() {
    const selectedProducts = []
    this.productTargets.forEach((element) => {
      if (element.value > 0) {
        selectedProducts.push({
          productId: element.dataset.productId,
          replacingProductId: this.replacingProductIdValue,
          replacingLineItemId: this.replacingLineItemIdValue,
          groupedAcrossSources: this.groupedAcrossSourcesValue,
          term: this.searchTarget['lib--search'].inputTarget.value,
          quantity: parseInt(element.value),
          price: element.dataset.price,
          name: element.dataset.name,
        })

        // Clear selection for when the next time the modal is open
        element.value = 0
      }
    })
    if (selectedProducts.length > 0) {
      this.element.dispatchEvent(
        new CustomEvent('selected_products', { detail: selectedProducts })
      )
    }
  }
}
