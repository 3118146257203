import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'importTextInput']

  connect() {
    this.maybeDisableSubmit()
  }

  _isImportTextEmpty = () => {
    const txt = this.importTextInputTarget.value
    return txt === '' || txt === null || txt === undefined
  }

  maybeDisableSubmit = () => {
    if (this._isImportTextEmpty()) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }
}
