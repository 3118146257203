import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { activeClasses: Array, classesToRemove: Array }
  static targets = ['button']

  activate(event) {
    this.buttonTargets.forEach((target) => {
      if (target == event.currentTarget) {
        target.classList.remove(...this.classesToRemoveValue)
        target.classList.add(...this.activeClassesValue)
      } else {
        target.classList.add(...this.classesToRemoveValue)
        target.classList.remove(...this.activeClassesValue)
      }
    })
  }
}
