class Bridge {
  static basketUpdated() {
    this.postMessage('basketUpdated')
  }

  // Sends a message to the native app, if active.
  static postMessage(name, data = {}) {
    window.lollipop_app_channel?.postMessage(
      JSON.stringify({ name: name, data: data })
    )
  }
}

// Expose this on the window object so TurboNative can interact with it
window.TurboNativeBridge = Bridge
export default Bridge
