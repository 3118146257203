import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pre', 'post']

  go() {
    const that = this
    setTimeout(() => {
      that.preTargets.forEach((e) => e.classList.add('hidden'))
      that.postTargets.forEach((e) => e.classList.remove('hidden'))
    }, 1000)
  }
}
