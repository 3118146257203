import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  submit(event) {
    const form = event.target.form || event.target.closest('form')
    if (!form) return

    if (form.dataset['remote']) {
      Rails.fire(form, 'submit')
    } else {
      form.requestSubmit()
    }
  }
}
