import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['label', 'checkbox']

  onChange(event) {
    if (this.labelTarget.dataset.deselected === 'false') {
      this.labelTarget.dataset.deselected = 'true'
      this.labelTarget.innerText =
        this.labelTarget.dataset.allUnselectedLabelValue
      this.checkboxTargets.forEach((c) => {
        if (c.disabled) {
          return
        }

        c.checked = false
      })
    } else {
      this.labelTarget.dataset.deselected = 'false'
      this.labelTarget.innerText =
        this.labelTarget.dataset.allSelectedLabelValue
      this.checkboxTargets.forEach((c) => {
        if (c.disabled) {
          return
        }

        c.checked = true
      })
    }
  }
}
