import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'otherContainer']

  updateOtherInputVisibility() {
    this.hideOtherInput()
    if (this.selectedValue == 'other') {
      this.showOtherInput()
    }
  }

  get options() {
    return this.selectTarget.options
  }

  get selectedValue() {
    return this.options[this.selectTarget.selectedIndex].value
  }

  hideOtherInput() {
    this.otherContainerTarget.classList.add('hidden')
  }

  showOtherInput() {
    this.otherContainerTarget.classList.remove('hidden')
  }
}
