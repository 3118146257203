import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['counterCta', 'emptyCta', 'counterLabel']
  static values = {
    location: String,
    productCount: Number,
  }

  searchCleared() {
    Turbo.visit(this.locationValue, { action: 'replace' })
  }

  productCountIncremented() {
    this.productCountValue++
    this.handleProductCount()
  }

  productCountDecremented() {
    this.productCountValue--
    this.handleProductCount()
  }

  handleProductCount() {
    if (this.productCountValue === 0) {
      this.emptyCtaTarget.classList.remove('hidden')
      this.counterCtaTarget.classList.add('hidden')
    } else {
      this.emptyCtaTarget.classList.add('hidden')
      this.counterCtaTarget.classList.remove('hidden')

      this.counterLabelTarget.innerHTML = `<span class='font-normal'>${this.productCountValue} added</span>&nbsp;&nbsp•&nbsp;&nbsp;Continue`
    }
  }
}
