// Load all the Stimulus controllers within the controller and components
// directory and all subdirectories. Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import { Autocomplete } from 'stimulus-autocomplete'
import NestedForm from 'stimulus-rails-nested-form'

const application = Application.start()

const standaloneControllers = require.context(
  'controllers',
  true,
  /_controller\.js$/
)
const componentControllers = require.context(
  '../components',
  true,
  /_controller\.js$/
)

application.load([
  ...definitionsFromContext(standaloneControllers),
  ...definitionsFromContext(componentControllers),
])

application.register('autocomplete', Autocomplete)
application.register('nested-form', NestedForm)
