// Usage
// %div(data-controller="lazy-load"){ "data-lazy-load-src-value": groceries_path }
import { Controller } from 'stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    src: String,
  }

  initialize() {
    this.beforeCache = this.beforeCache.bind(this)
  }

  connect() {
    if (this.isPreview) return

    document.addEventListener('turbo:before-cache', this.beforeCache)

    this.loadPage()
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.beforeCache)
  }

  async loadPage() {
    if (this.loadPageCalled) return
    this.loadPageCalled = true

    // No-need to re-fetch if there's already content inside
    if (this.element.innerHTML != '') return

    const response = await get(this.srcValue)

    if (response.ok) {
      const body = await response.text
      this.element.innerHTML = body
    }
  }

  // Cleanup to avoid back button page flickers
  beforeCache() {
    this.element.innerHTML = ''
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }
}
