import { Controller } from 'stimulus'
import { track } from 'src/analytics_event_tracker'

export default class extends Controller {
  static values = {
    eventName: String,
    eventProperties: Object,
  }

  track() {
    if (!this.hasEventNameValue) {
      return
    }

    track(this.eventNameValue, this.eventPropertiesValue)
  }
}
