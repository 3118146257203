import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = {
    supplier: String,
  }

  selectedProducts(event) {
    const line = event.detail[0]

    Rails.ajax({
      type: 'get',
      url: `/playlists/products/${line.replacingProductId}/substitute_products/${line.productId}`,
      data: new URLSearchParams({
        quantity: line.quantity,
        supplier: this.supplierValue,
      }),
      success: (data) => Turbo.renderStreamMessage(data),
    })
  }
}
