import { Controller } from 'stimulus'
import { focusFirstfocusableElement } from 'src/focus-helper'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['toggleable']
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle(event) {
    event.preventDefault()

    this.openValue = !this.openValue
  }

  toggleOnce(event) {
    event.preventDefault()
    if (!this.hasToggled) {
      this.openValue = !this.openValue
      this.hasToggled = true
    }
  }

  show(event) {
    event.preventDefault()
    this.toggleableTargets.forEach((target) => {
      if ('transitionEnterStart' in target.dataset === true) {
        enter(target)
      } else {
        target.classList.remove(this.toggleClass)
      }
      focusFirstfocusableElement(target)
    })
  }

  hide(event) {
    event.preventDefault()
    this.toggleableTargets.forEach((target) => {
      if ('transitionEnterStart' in target.dataset === true) {
        leave(target)
      } else {
        target.classList.add(this.toggleClass)
      }
      focusFirstfocusableElement(target)
    })
  }

  openValueChanged() {
    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass)
      focusFirstfocusableElement(target)
    })
  }
}
