import { Controller } from 'stimulus'
import { clearCache } from '@hotwired/turbo'

export default class extends Controller {
  initialize() {
    this.afterRequest = this.afterRequest.bind(this)
  }

  connect() {
    this.element.addEventListener(
      'turbo:before-fetch-response',
      this.afterRequest
    )
  }

  disconnect() {
    this.element.removeEventListener(
      'turbo:before-fetch-response',
      this.afterRequest
    )
  }

  afterRequest({ detail }) {
    if (detail.fetchResponse.succeeded) {
      clearCache()
    }
  }
}
