import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['container']
  static values = {
    supplier: String,
  }

  select(event) {
    const line = event.detail[0]

    Rails.ajax({
      type: 'get',
      url: `/playlists/products/${line.productId}`,
      data: new URLSearchParams({
        ...line,
        supplier: this.supplierValue,
      }).toString(),
      success: (data) =>
        this.containerTarget.insertAdjacentHTML(
          'beforeend',
          data.body.innerHTML
        ),
    })
  }
}
