import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['replace']

  replace = (event) => {
    let [data, status, xhr] = event.detail
    this.replaceTarget.outerHTML = data.body.innerHTML
  }

  replaceMulti = (event) => {
    let [data, status, xhr] = event.detail
    for (const [key, value] of Object.entries(data)) {
      let targetId = event.target.dataset[`replace-${key}`]
      if (!targetId) {
        console.warn(
          `Undefined target identifier for replaceMulti. Add data attribute for replace--${key}`
        )
      } else {
        let target = this.replaceTargets.find(
          (element) => element.dataset['replaceId'] == targetId
        )
        if (!target) {
          console.warn(
            `Cannot find a target element to replace. Add a replace target with replace-id ${targetId}`
          )
        } else {
          target.outerHTML = value
        }
      }
    }
  }
}
