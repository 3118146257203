// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'src/sentry_error_monitoring'

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'

import 'trix'
import '@rails/actiontext'

// The following imports are exposed via Webpack's expose-loader plugin
import 'croppie'

import '../stylesheets/application.css'
import 'channels'
import 'components'
import 'controllers'

import UJS from 'src/ujs'
import 'src/confirm'
import 'src/turbo_native/bridge'
import 'src/turbo_native/listeners'
import 'src/turbo_keep_scroll'

import '@hotwired/turbo-rails'

function init() {
  UJS.start()
  Rails.start()
  ActiveStorage.start()
}

if (!window.isAppInitialized) {
  init()
  window.isAppInitialized = true
}

import trackFrontendAnalytics from '../frontend_analytics'

document.addEventListener('turbo:load', () => {
  trackFrontendAnalytics()
})
