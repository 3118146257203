import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['drawer', 'icon']

  toggle() {
    this.drawerTarget.classList.toggle('hidden')
    this.iconTarget.classList.toggle('transform')
    this.iconTarget.classList.toggle('rotate-180')
  }
}
