import { Controller } from 'stimulus'
import { track } from 'src/analytics_event_tracker'

var sainsburysWindow = null

export default class extends Controller {
  static values = {
    waitScreenPath: String,
  }

  static targets = ['openSainsburys']

  connect() {
    if (this.isPreview) return

    this.openSainsburysWindow()
    const opened = !!this.sainsburysWindow
    if (opened) {
      this.visitWaitScreen()
    }

    track('Checkout: Auto Open Sainsburys', { auto_opened: opened })
  }

  openSainsburysWindow() {
    if (!this.sainsburysWindow || this.sainsburysWindow.closed) {
      this.sainsburysWindow = window.open(
        this.openSainsburysTarget.href,
        '_blank'
      )
    } else {
      this.sainsburysWindow.focus()
    }
  }

  visitWaitScreen() {
    Turbo.visit(this.waitScreenPathValue, { action: 'replace' })
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }
}
