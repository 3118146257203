import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'showBeforeChange', 'showAfterChange']

  connect() {
    if (this.hasShowBeforeChangeTarget && this.hasShowAfterChangeTarget) {
      this.listenForChangesInForm()
    }
  }

  listenForChangesInForm() {
    this.formTarget.addEventListener('change', () => {
      this.showBeforeChangeTarget.classList.add('hidden')
      this.showAfterChangeTarget.classList.remove('hidden')
    })
  }
}
