import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['totalCost', 'servingCost', 'servingSize', 'ingredient']

  formatCurrency(amount, currency = 'GBP') {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currency,
    }).format(amount / 100)
  }

  connect() {
    this.update()
  }

  currentServingSize() {
    return parseInt(this.servingSizeTarget.value, 10)
  }

  update() {
    const totalCost = this.getTotalCost()
    this.totalCostTarget.innerText = this.formatCurrency(totalCost)
    if (this.hasServingCostTarget) {
      this.servingCostTarget.innerText = this.formatCurrency(
        totalCost / this.currentServingSize()
      )
    }
  }

  getTotalCost() {
    return this.ingredientTargets
      .filter((checkboxNode) => checkboxNode.checked)
      .map((checkboxNode) => {
        var costAmount = checkboxNode.dataset.ingredientCostAmount
        var parsedCostAmount = parseInt(costAmount, 10)
        if (isNaN(parsedCostAmount)) {
          var ingredientId = checkboxNode.data.set.ingredientId
          var ingredientDescription = checkboxNode.parentElement.innerText
          throw new Error(
            'Failed to parse money value ' +
              costAmount +
              ' for ingredient #' +
              ingredientId +
              ' "' +
              ingredientDescription +
              '"'
          )
        }
        return parsedCostAmount
      })
      .reduce((acc, n) => acc + n, 0)
  }
}
