import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputField', 'control']

  static values = { min: Number, max: Number }

  connect = () => {
    this.element[this.identifier] = this
  }

  increase = () => {
    if (this.canIncrease()) {
      this.setValue(this.currentNumericValue() + 1)
      this.element.dispatchEvent(
        new CustomEvent('numberInputIncremented', { bubbles: true })
      )
      this.animateControls()
    }
  }

  decrease = () => {
    if (this.canDecrease()) {
      this.setValue(this.currentNumericValue() - 1)
      this.element.dispatchEvent(
        new CustomEvent('numberInputDecremented', { bubbles: true })
      )
      this.animateControls()
    }
  }

  currentNumericValue = () => {
    return Number.parseInt(this.inputFieldTarget.value, 10)
  }

  setValue = (val) => {
    this.inputFieldTarget.value = val
    this.inputFieldTarget.dispatchEvent(new Event('change'))
  }

  canIncrease = () => {
    if (!this.hasMaxValue) {
      return true
    }

    return this.currentNumericValue() < this.maxValue
  }

  canDecrease = () => {
    if (!this.hasMinValue) {
      return true
    }

    return this.currentNumericValue() > this.minValue
  }

  animateControls = () => {
    this.controlTargets.forEach((c) => {
      c.classList.add('animate-pulse')
    })
  }
}
