import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['visibleOnlyBeforeOpen', 'visibleOnlyAfterOpen']
  static values = {
    modalId: String,
    pickerId: String,
    replacingProductId: String,
    replacingLineItemId: String,
    searchTerm: String,
  }

  toggle(event) {
    event.preventDefault() // NOTE: Might have been clicked inside a form on a button
    const modalElement = document.getElementById(this.modalIdValue)
    const modal = modalElement['lib--modal']
    modal.bindExternalTrigger(this.element)
    modal.toggle()

    if (this.hasPickerIdValue) {
      const pickerElement = document.getElementById(this.pickerIdValue)
      const picker = pickerElement['lib--product-picker']
      picker.bindValues({
        replacingProductId: this.replacingProductIdValue,
        replacingLineItemId: this.replacingLineItemIdValue,
        searchTerm: this.searchTermValue,
      })
    }

    // TODO: Figure out why arrow functions aren't fixing `this` here
    const that = this
    modal.element.addEventListener('modal:hide', (event) => {
      that.visibleOnlyBeforeOpenTargets.forEach((e) =>
        e.classList.add('hidden')
      )
      that.visibleOnlyAfterOpenTargets.forEach((e) =>
        e.classList.remove('hidden')
      )
    })
  }
}
