// frontend/javascript/turbo-scroll.js
// This JavaScript saves the scroll position of each element with the "data-turbo-keep-scroll" attribute.
// When the next page is renders, we restore the scroll position.
;(function () {
  let scrollTop = 0

  addEventListener('turbo:before-render', ({ target }) => {
    if (scrollTop) {
      // Hack that's needed, as otherwise Turbo will scroll
      // to the top
      Turbo.navigator.currentVisit.scrolled = true
    }
  })

  addEventListener('turbo:before-fetch-request', ({ target }) => {
    if (target.hasAttribute('data-turbo-keep-scroll')) {
      scrollTop = document.scrollingElement.scrollTop
    }
  })

  addEventListener('turbo:render', () => {
    if (scrollTop) {
      document.scrollingElement.scrollTo(0, scrollTop)
      scrollTop = 0
    }
  })
})()
