import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'container',
    'ingredientsForm',
    'servingSize',
    'productPicker',
    'ingredient',
    'quantity',
  ]
  static values = { url: String }

  connect() {
    this.listenForSelectedProducts()
  }

  listenForSelectedProducts() {
    const that = this
    this.productPickerTargets.forEach((picker) =>
      picker.addEventListener('selected_products', (event) => {
        const line = event.detail[0]

        const checkbox = that.ingredientTargets.find(
          (e) => e.dataset.ingredientId == picker.dataset.ingredientId
        )
        checkbox.value = line.productId
        checkbox.checked = true
        checkbox.dataset.ingredientCostAmount = line.price * line.quantity

        const quantityField = that.quantityTargets.find(
          (e) => e.dataset.ingredientId == picker.dataset.ingredientId
        )
        quantityField.value = line.quantity

        if (window.analytics) {
          window.analytics.track('ingredientSubstituteSelected', {
            ingredientId: picker.dataset.ingredientId,
            selectedProductId: line.productId,
            selectedProductName: line.name,
          })
        } else {
          console.log('ingredientSubstituteSelected', {
            ingredientId: picker.dataset.ingredientId,
            selectedProductId: line.productId,
            selectedProductName: line.name,
          })
        }

        // NOTE: We resubmit with the same serving size here to get the new
        // line from the product picker.  We could of course just re-render the
        // line, but _only_ if they are the same format - they might actually
        // be if we let people modify ingredients inline.
        this.handleServingSizeChange(this.currentServingSize())
      })
    )
  }

  increaseServingSize(event) {
    event.currentTarget.disabled = true
    this.handleServingSizeChange(this.currentServingSize() + 1)
  }

  decreaseServingSize(event) {
    event.currentTarget.disabled = true
    const targetSize = this.currentServingSize() - 1
    if (targetSize > 0) {
      this.handleServingSizeChange(targetSize)
    }
  }

  handleServingSizeChange(servingSize) {
    const that = this
    this.servingSizeTarget.value = servingSize
    const checkState = this.ingredientTargets.map((checkbox) => ({
      id: checkbox.dataset.ingredientId,
      checked: checkbox.checked,
    }))

    const formState = new FormData(this.ingredientsFormTarget)
    formState.set('_method', 'post')

    Rails.ajax({
      type: 'post',
      dataType: 'script',
      url: this.urlValue,
      data: new URLSearchParams(formState).toString(),
      success: (data) => {
        that.containerTarget.innerHTML = data.body.innerHTML
        that.listenForSelectedProducts()

        checkState.forEach((ingredient) => {
          const i = that.ingredientTargets.find(
            (target) => target.dataset.ingredientId == ingredient.id
          )
          if (i) {
            i.checked = ingredient.checked
          }
        })

        const event = new CustomEvent('update-recipe-costs')
        window.dispatchEvent(event)
      },
      error: (data) => {
        // TODO: fails when subs present
        alert(`Error: ${data.body.innerHTML}`)
      },
    })
  }

  currentServingSize() {
    return parseInt(this.servingSizeTarget.value, 10)
  }
}
