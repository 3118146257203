import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['destination']

  click(event) {
    this.destinationTarget.click()

    // NOTE: We stop the event bubbling up as the new event
    // should take care of ongoing effects. If we don't do this
    // then it can interfere with considerHiding behaviour
    event.stopPropagation()
  }
}
