export const focusFirstfocusableElement = (target) => {
  const focusedElement =
    target.querySelector('[autofocus]') ||
    target.querySelector('input[type="text"]') ||
    target.querySelector('input[type="search"]')

  if (focusedElement) {
    if (focusedElement.value && focusedElement.setSelectionRange)
      focusedElement.setSelectionRange(
        focusedElement.value.length,
        focusedElement.value.length
      )
    setTimeout(() => {
      focusedElement.focus()
    })
  }
}
