function hasImageFailedToLoad(imgEl) {
  return imgEl.complete && imgEl.naturalHeight === 0
}

/**
 * Replaces an image with the backup image. If the passed element is
 * not an image, it will be ignored without raising an error.
 */
function loadBackupImage(imgEl) {
  if (
    imgEl instanceof HTMLImageElement &&
    imgEl.dataset.backupImage != null &&
    imgEl.dataset.backupImage !== imgEl.src
  ) {
    imgEl.src = imgEl.dataset.backupImage
  }
}

/**
 * Registers event handler to load backup images for all images that
 * fail to load and have a backup image defined. Images that have
 * already failed to load will be updated, too.
 */
function start() {
  // Image error events don't bubble up, but they do participate in the
  // event capture phase and we take advantage of that.
  window.addEventListener('error', (e) => loadBackupImage(e.target), true)

  // Update images that have already failed to load, too
  for (var imgEl of document.querySelectorAll('img')) {
    if (hasImageFailedToLoad(imgEl)) {
      loadBackupImage(imgEl)
    }
  }
}

export default { start }
