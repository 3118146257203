import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'imagePreview',
    'imagePreviewContainer',
    'emptyImagePreview',
    'userUploadedImageInput',
    'retryButton',
  ]

  connect = () => {
    if (this.imagePreviewTarget.getAttribute('src') !== '') {
      this.hideEmptyImagePreview()
      this.showImagePreview()
    }
  }

  chooseImageToUpload = (event) => {
    this.userUploadedImageInputTarget.click()
    event.preventDefault()
  }

  onImageFileChange = (event) => {
    let files = this.userUploadedImageInputTarget.files
    if (files.length > 0) {
      this.loadFileFieldPreview(files[0])
      this.submit()
    } else {
      // don't send empty file to backend
      this.imagePreviewTarget.src = ''
      this.imagePreviewContainerTarget.classList.add('hidden')
      this.emptyImagePreviewTarget.classList.remove('hidden')
    }
  }

  loadFileFieldPreview = (file) => {
    let fr = new FileReader()
    fr.readAsDataURL(file)
    fr.onload = (e) => {
      // rendering arbitrary SVG could be an XSS vuln and we don't accept it in
      // the backend
      if (e.currentTarget.result.slice(0, 18) !== 'data:image/svg+xml') {
        this.imagePreviewTarget.src = e.currentTarget.result
        this.showImagePreview()
      }
      if (this.hasEmptyImagePreviewTarget) {
        this.hideEmptyImagePreview()
      }
    }
  }

  showImagePreview = () => {
    this.imagePreviewContainerTarget.classList.remove('hidden')
  }

  hideEmptyImagePreview = () => {
    this.emptyImagePreviewTarget.classList.add('hidden')
  }

  submit(event) {
    // can't use lib--submit-on-change because we need custom onChange behaviour
    const form = this.element
    if (!form) return

    if (form.dataset['remote']) {
      Rails.fire(form, 'submit')
    } else {
      form.requestSubmit()
    }
  }
}
