import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['click']

  static values = {
    timeout: Number,
  }

  connect() {
    this.timeout = setTimeout(() => {
      this.clickTarget.click()
    }, this.timeoutValue)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
