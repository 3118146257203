import { Controller } from 'stimulus'
import { navigator, session } from '@hotwired/turbo'

export default class extends Controller {
  static targets = [
    'container',
    'trigger',
    'scrim',
    'inactiveBody',
    'activeBody',
  ]

  static values = {
    analyticsProperties: Object,
    autoOpen: Boolean,
  }

  connect = () => {
    this.element[this.identifier] = this

    if (this.autoOpenValue) {
      this.show()
    }
  }

  disconnect() {
    this.hide()
  }

  show() {
    // Lock the scroll and save current scroll position
    this.lockScroll()

    // Unhide the modal
    this.containerTarget.classList.remove('hidden')

    // Insert the background
    this.scrimTarget.classList.remove('hidden')

    this.dispatch('modal:show')
    this.track('modalOpened')
  }

  hide = () => {
    if (this.isHidden()) {
      return
    }
    // Hide the modal
    this.containerTarget.classList.add('hidden')

    if (this.hasActiveBodyTarget) {
      this.activeBodyTarget.innerHtml = ''
      this.activeBodyTarget.classList.add('hidden')
      this.inactiveBodyTarget.classList.remove('hidden')
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll()

    // Remove the background
    this.scrimTarget.classList.add('hidden')

    // Restore url
    this.restorePreviousUrl()

    this.dispatch('modal:hide')
    this.track('modalClosed')
  }

  toggle = () => {
    if (this.isHidden()) {
      this.show()
    } else {
      this.hide()
    }
  }

  isHidden = () => {
    return this.containerTarget.classList.contains('hidden')
  }

  bindExternalTrigger = (triggerDomElement) => {
    this.externalTrigger = triggerDomElement
  }

  closeBackground(e) {
    if (e.target === this.containerTarget) {
      this.hide(e)
    }
  }

  closeWithKeyboard(e) {
    if (
      e.keyCode === 27 &&
      !this.containerTarget.classList.contains('hidden')
    ) {
      this.hide(e)
    }
  }

  track(event, properties = {}) {
    if (!this.hasAnalyticsPropertiesValue) {
      return
    }

    if (window.analytics) {
      window.analytics.track(event, this.analyticsPropertiesValue)
    } else {
      console.log(event, this.analyticsPropertiesValue)
    }
  }

  dispatch(eventName) {
    this.element.dispatchEvent(new CustomEvent(eventName))
  }

  setDynamicBody = (html) => {
    this.inactiveBodyTarget.classList.add('hidden')
    this.activeBodyTarget.classList.remove('hidden')
    this.activeBodyTarget.innerHTML = html
    this.activeBodyTarget.parentElement.scrollTop = 0
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return

    document.documentElement.scrollTop = this.scrollPosition
  }

  changeUrl(url) {
    this.currentUrl = new URL(url)
    navigator.history.push(new URL(url))
    session.view.lastRenderedLocation = new URL(url)
  }

  restorePreviousUrl() {
    if (!this.currentUrl) {
      return
    }

    if (this.currentUrl.href == new URL(window.location).href) {
      // Pause history navigation listening, so we can
      // manually pop a url from the stack without triggering
      // a turbo reload
      navigator.history.stop()
      history.back()

      // as back() is an async method, we need to wait a bit before
      // starting Turbo history again
      window.setTimeout(() => {
        navigator.history.start()
      }, 80)
    }
  }
}
