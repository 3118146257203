import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['id', 'term', 'units', 'form', 'afterRedirect']

  select(event) {
    const line = event.detail[0]
    this.idTarget.value = line.productId
    this.termTarget.value = line.term
    this.unitsTarget.value = line.quantity

    this.afterRedirectTarget.value = 'edit' // Tells the form to redirect to the same page i.e. save + reload

    this.formTarget.submit()
  }
}
