import { Controller } from 'stimulus'
import { navigator } from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['productChangeForm', 'productIdField', 'unitsField']

  select = (event) => {
    const line = event.detail[0]
    this.productIdFieldTarget.value = line.productId
    this.unitsFieldTarget.value = line.quantity
    this.productChangeFormTarget.requestSubmit()
  }
}
