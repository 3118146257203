import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['overlay']

  initialize() {
    this.afterRequest = this.afterRequest.bind(this)
    this.beforeRequest = this.beforeRequest.bind(this)
  }

  connect() {
    this.element.addEventListener('turbo:click', this.beforeRequest)

    this.element.addEventListener(
      'turbo:before-fetch-response',
      this.afterRequest
    )

    this.element.addEventListener('turbo:submit-start', this.beforeRequest)
  }

  disconnect() {
    this.element.removeEventListener('turbo:click', this.beforeRequest)
    this.element.removeEventListener(
      'turbo:before-fetch-response',
      this.afterRequest
    )
    this.element.removeEventListener('turbo:submit-start', this.beforeRequest)
  }

  beforeRequest(event) {
    const {
      detail: { formSubmission },
    } = event

    this.overlayTarget.classList.remove('hidden')
  }

  afterRequest(event) {
    this.overlayTarget.classList.add('hidden')
  }
}
