import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['nextPageLink', 'list']

  initialize() {
    this.intersectionObserver = new IntersectionObserver((entries) =>
      this.loadNextPageIfNeeded(entries)
    )
  }

  connect() {
    // NOTE: See this blog post for why this is useful:
    // https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    this.element[this.identifier] = this

    if (this.hasNextPageLinkTarget) {
      this.intersectionObserver.observe(this.nextPageLinkTarget)
    }
  }

  disconnect() {
    this.element[this.identifier] = null

    if (this.hasNextPageLinkTarget) {
      this.intersectionObserver.unobserve(this.nextPageLinkTarget)
    }
  }

  loadNextPageIfNeeded(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.nextPageLinkTarget.click()
      }
    })
  }

  clearList() {
    this.listTarget.innerHTML = null
    this.nextPageLinkTarget.classList.remove('hidden')
  }

  onNextPageLoad(event) {
    let [data, status, xhr] = event.detail

    this.listTarget.insertAdjacentHTML('beforeend', data.html)

    if (data.next_page_url) {
      this.nextPageLinkTarget.href = data.next_page_url
    } else {
      this.nextPageLinkTarget.classList.add('hidden')
    }
  }

  onNextPageLoadError(event) {
    let [data, status, xhr] = event.detail

    throw new Error('Failed to load next page. Status code: ' + status)
  }
}
