import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'button', 'scrim']

  toggleMenu = () => {
    if (this.menuVisible()) {
      this.hideMenu()
    } else {
      this.showMenu()
    }
  }

  hideMenu = () => {
    this.menuTarget.classList.add('hidden')
    this.scrimTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  showMenu = () => {
    this.menuTarget.classList.remove('hidden')
    this.scrimTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
  }

  considerHidingMenu = (event) => {
    //listen to the whole window for click events so that interaction outside the modal closes it
    const targetIsMenuItself =
      this.menuTarget.contains(event.target) ||
      this.buttonTarget.contains(event.target)

    if (targetIsMenuItself) {
      return
    }

    this.hideMenu()
  }

  menuVisible = () => {
    !this.menuTarget.classList.contains('hidden')
  }
}
