import { Controller } from 'stimulus'

function getBackgroundSize(input) {
  const min = +input.min || 0
  const max = +input.max || 100
  const value = +input.value

  const size = ((value - min) / (max - min)) * 100

  return size
}
export default class extends Controller {
  static targets = ['rangeSlider', 'bubble']

  connect() {
    this.updateValue()
  }

  updateValue() {
    const range = this.rangeSliderTarget
    const val = range.value
    const min = range.min ? range.min : 0
    const max = range.max ? range.max : 100
    const newVal = Number(((val - min) * 100) / (max - min))

    this.bubbleTarget.innerText = val + ' ' + this.bubbleTarget.dataset.suffix

    this.bubbleTarget.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`

    this.setBackgroundSize()
  }

  setBackgroundSize() {
    const range = this.rangeSliderTarget
    range.style.setProperty('--background-size', `${getBackgroundSize(range)}%`)
  }
}
