import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  static values = {
    timeoutMs: Number,
  }

  connect() {
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, this.timeoutMsValue)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
