import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'button',
    'optionsContainer',
    'option',
    'valueInput',
    'displayValue',
  ]

  showOptions(event) {
    this.hasJustBeenOpened = true

    this.optionsContainerTarget.classList.remove('hidden')
  }

  optionSelected(event) {
    event.stopImmediatePropagation()
    this.valueInputTarget.value = event.target.getAttribute('data-value')

    const selectedValue = event.currentTarget.dataset.value
    const selectedOption = this.optionTargets.find(
      (option) => option.dataset.value == selectedValue
    )

    this.displayValueTarget.innerText =
      selectedOption.getAttribute('data-label')

    this.hideOptions()

    // notify delivery_slot_picker_controller.js of the change
    this.valueInputTarget.dispatchEvent(new Event('change'))
  }

  hideOptions() {
    this.optionsContainerTarget.classList.add('hidden')
  }

  considerHidingOptions(event) {
    if (this.hasJustBeenOpened) {
      this.hasJustBeenOpened = false
      return
    }

    //listen to the whole window for click events so that interaction outside the element closes it
    if (this.optionsContainerTarget.contains(event.target)) {
      return
    }

    this.hideOptions()
  }
}
