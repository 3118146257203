// When the animation ends, we clean the classes and resolve the Promise

export const animateCSS = (element, animationName) => {
  // We create a Promise and return it
  return new Promise((resolve, reject) => {
    element.classList.add(...animationName.split(' '))
    element.addEventListener(
      'animationend',
      (event) => {
        event.stopPropagation()
        element.classList.remove(...animationName.split(' '))
        resolve('Animation ended')
      },
      { once: true }
    )
  })
}

export const animateHide = (element, animationName) => {
  return animateCSS(element, animationName).then(() => {
    element.classList.add('hidden')
  })
}
